import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './reroll.scss';
import { Button, Card, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const AshGuidesReroll: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-reroll'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_reroll.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Reroll Guide</h1>
          <h2>
            Start your adventure in Ash Echoes with the best character using our
            Reroll Guide.
          </h2>
          <p>
            Last updated: <strong>30/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Should you reroll?" />
        <p>
          <strong>No.</strong>
        </p>
        <p>
          Rerolling in Ash Echoes is pretty much pointless. You get a free 6✦
          selector at 30 summons, then another 6✦ selector at 300 summons, and a
          free random 6✦ by progressing through the beginner missions and
          finishing Chapter 3. <strong>Yes, that's three 6✦ characters!</strong>
        </p>
        <p>
          While it’s true that you can start the game with a{' '}
          <strong>Guest Account</strong>, deleting the Guest Account won’t work,
          as its data will be bound to your device. You can delete an account
          bound to an email, but it will take a few business days for the
          process to be completed. This limits your rerolling to{' '}
          <strong>emulators</strong> where you can create multiple instance
          quite easily. Furthermore, the entire rerolling process can take up to{' '}
          <strong>15 minutes of playing manually</strong> through the entirety
          of the Prologue.
        </p>
        <p>
          If despite all these warnings you still wish to proceed… here are some
          tips.
        </p>
        <SectionHeader title="How to Reroll" />
        <p>There are a couple of ways to go about it.</p>
        <ul>
          <li>
            The first one is by using several emails - one for each attempt.
          </li>
          <li>
            The second one is by using the{' '}
            <strong>
              Multi-Instance function in an emulator, and creating a Guest
              account for each attempt.
            </strong>{' '}
            Note that this method requires a PC that can handle multiple
            instances at once, and due to the nature of Ash Echoes’ gameplay,
            they may go out of sync easily.
          </li>
        </ul>
        <p>
          Regardless of your choice, it’s advised that you keep a doc or sheet
          to take notes of your attempts until you’re satisfied with your
          starter account.
        </p>
        <p>
          If you're planning to reroll using Emulator, we suggest using{' '}
          <strong>LDPlayer</strong>! You can download it from the link below and
          by doing so, support the website!
        </p>
        <div className="ash-promo">
          <h4>Play & Reroll Ash Echoes on PC</h4>
          <OutboundLink
            href="https://leap.ldplayer.gg/T4ndFUlZ0"
            target="_blank"
          >
            <Button variant="primary">Reroll now on LDPlayer</Button>
          </OutboundLink>
        </div>
        <SectionHeader title="Tips for the Best Starter Account" />
        <StaticImage
          src="../../../images/ash/generic/reroll_1.webp"
          alt="Guides"
        />
        <p>
          After going through the Prologue and claiming your resources, you’ll
          face two types of banners:{' '}
          <strong>Echomancers (Characters) and Memory Traces (Cards)</strong>.
          The ideal reroll means getting at least one 6✦ Echomancer and at least
          one SSR Memory Trace with the initial resources. If you don’t want to
          spend too much time rerolling, guaranteeing a 6✦ with the starter
          resources is enough.
        </p>
        <SectionHeader title="Who to Aim For" />
        <p>
          Typically, the featured rate up character will be a good choice to
          start with. All new releases in Ash Echoes so far have been good
          additions, and usually, each patch is tailored for the current rate up
          Echomancer, which will guarantee you a smooth start. Another option of
          initial banner to go for is the Standard Rate up. While Ash Echoes
          doesn’t have any limited character so far, these banners sometimes
          feature rate ups for characters who are considered the best in their
          role or generally a great addition to any account overall.
        </p>
        <p>
          For more information about these characters and their future
          viability, check out our Tier List:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/ash-echoes/tier-list"
            image={
              <StaticImage
                src="../../../images/ash/categories/category_tier.webp"
                alt="Tier List"
              />
            }
          />
        </Row>
        <SectionHeader title="Good 6✦ starter options" />
        <p>
          Aside from the current featured Echomancer, there are a few characters
          who are core to their team and definitely worth keeping if you stumble
          upon them:
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top Fire">
            <p className="name">Cyros</p>
            <p>Burst, Elemental Zone</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="cyros"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                The core of Fire Teams and overall one of the best block gauge
                breakers. He can also provide consistent creation of Fire Zones,
                while sustaining a respectable personal DPS.
              </p>
              <p>
                As a bonus, if you get him early, you can use one of your
                selectors for his first dupe, which allows him to cast his
                ultimate right at the start of the battle.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box a">
                <h6>A</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Corrosion">
            <p className="name">Lorelle</p>
            <p>DPS, Tank</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="lorelle"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                A solid frontline and the backbone of the Corrosion Team.
                Lorelle can adapt between offensive and defensive stances, break
                gauges, diverge damage from allies to herself
              </p>
              <p>
                If you get her first dupe (be it with selector or not), she can
                apply her “Polyphony” buffs even when she isn’t in the field.
                Truly a character who can fit any team.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box a">
                <h6>A</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Water">
            <p className="name">Longqing</p>
            <p>Heal, Barrier</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="longqing"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                Boasting the highest healing potential in the game, Longqing is
                another character who can fit any team. Her barriers from “Aqua
                Bliss” provide up to 80% damage reduction from projectiles and
                up to 20% damage reduction from other sources, as well as being
                one of the fastest way to stack [Inspire] if you’re using her
                alongside the Memory Trace “Nightmare”. Her ultimate is even
                more powerful, creating one big dome barrier that deals damage
                and provides stronger defensive buffs than her “Aqua Bliss”
                barriers.
              </p>
              <p>
                While Longqing can fit easily in any team and is almost a “must
                have”, she still loses the spot of most used and most
                comprehensive healer to 5✦ Echomancer Freda.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box s">
                <h6>S</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Physical">
            <p className="name">Luke</p>
            <p>DPS, Summon, Barrier</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="luke"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                If you’re going for Cen Ying on launch banner, you might as well
                guarantee the full package. Luke is the second core member of
                the Physical team.
              </p>
              <p>
                Even without Cen Ying, his wide variety of utility ranging from
                taunt to barriers and summons make him a great addition to any
                account and almost any team.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box a">
                <h6>A</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
        <SectionHeader title="Honorable 5✦ Mentions" />
        <p>
          Below you can find great 5✦ Echomancers that you should be on the
          lookout for while rerolling.
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top Water">
            <p className="name">Freda</p>
            <p>Heal, Support</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="freda"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                Arguably the best support in the game, you’ll see her in almost
                every team thanks to her buffs and passive healing capabilities.
                It’s recommended to get Freda to 3 dupes as soon as possible.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box s">
                <h6>S</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Lightning">
            <p className="name">Lydia</p>
            <p>DPS, AoE ATK</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="lydia"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                A 5✦ with the damage potential of a 6✦. A great pick for
                beginners, especially if you get Cyros or plan on building an
                Electrocution team.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first a">
                <h6>A</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box b">
                <h6>B</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Corrosion">
            <p className="name">Caroline</p>
            <p>Burst, Heal</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <AshCharacter
                  slug="caroline"
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p className="summary">
                <strong>Summary:</strong>
              </p>
              <p>
                The second core for the Corrosion team. Ideal if you have picked
                Lorelle. Caroline has the best Leader skill for the Corrosion
                Team until Leilani’s release, and even after that, his
                versatility with AoE DMG and Healing remain of great utility.
              </p>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first b">
                <h6>B</h6>
                <p>Global</p>
              </div>
              <div className="ratings-box b">
                <h6>B</h6>
                <p>CN</p>
              </div>
            </div>
          </div>
        </div>
        <SectionHeader title="Initial SSR Memory Trace Recommendations" />
        <p>
          Similarly to the previous section, if you’re going for the Rate Up
          Echomancer, then the Rate Up Memory Trace will most likely match them
          well. Having good Memory Traces is essential in Ash Echoes, as they
          will determine the bulk of your team’s stats and additional skills.
          Below are some recommendations of essential Memory Traces that remain
          relevant on CN server, as well as what they’re used for - in no
          particular order.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Nightmare" mode="data" />
          </div>
        </div>
        <p>
          Great for general use, has good stats for the Director’s engraving,
          and is also a good way to stack inspire if you use Longqing or Tian
          Ruo.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="My Vision Is Clear!" mode="data" />
          </div>
        </div>
        <p>Core for Electrocution teams.</p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Emptiness" mode="data" />
          </div>
        </div>
        <p>
          A strong offensive Memory Trace, with a special Trace Awakening skill
          that allow Bulwarks, Vanguards, and Strikers to fully recover the HP
          of the team once theirs fall below 50%, all while buffing the team’s
          ATK on top of that.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Blood Ruvia - Secret" mode="data" />
          </div>
        </div>
        <p>Core for Wind Teams.</p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Remaining Friends" mode="data" />
          </div>
        </div>
        <p>
          Great source of [Inspire] for any team with at least two Lightning
          Echomancers on the field.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Elegy At Dusk" mode="data" />
          </div>
        </div>
        <p>
          Core for Explosion teams, can work in tandem with “Remaining Friends”
          or one of these two Memory Traces can replace the other.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Growth" mode="data" />
          </div>
        </div>
        <p>
          One of the best TRM Memory Traces. If you look up its skills, you may
          wonder where you will get a steady source of shields. The Terminal
          automatically applies shields to Echomancers with less than 50% HP. On
          top of that, the Corrosion team needs 1000+ TRM to achieve optimal
          Corrosion Blast DMG, and there aren’t many good TRM cards at launch.
        </p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Shadows Of The Alley" mode="data" />
          </div>
        </div>
        <p>A very good general Memory Trace to grant stacks of [Inspire].</p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Hidden Blade" mode="data" />
          </div>
        </div>
        <p>Best MST Memory Trace for Corrosion teams.</p>
        <div className="ash-traces-container">
          <div className="ash-trace-box">
            <AshTrace name="Bad Soil Bloom" mode="data" />
          </div>
        </div>
        <p>Core for Physical teams.</p>
        <SectionHeader title="Gacha Rates" />
        <p>Getting straight to the point:</p>
        <ul>
          <li>
            Soft Pity starts at <strong>60</strong>, Hard Pity is at{' '}
            <strong>77</strong>.
          </li>
          <li>
            There’s a 50/50 system. When getting a 6✦ Echomancer (or SSR Memory
            Trace), you have 50% chance to obtain the featured one. If you lose
            the 50/50, the next 6✦ (or SSR Memory Trace) is guaranteed to be the
            featured.
          </li>
          <li>
            Pity carries over and is shared between Debut Rate Up and Standard
            Rate Up.
          </li>
          <li>
            The Standard Memory Trace banner has a Wishlist system to guarantee
            any SSR Memory Trace of your choice.
          </li>
          <li>A 5✦/SR or above is guaranteed every 10 summons.</li>
          <li>Probabilities for Echomancers:</li>
          <ul>
            <li>
              <strong>1.2%</strong> for 6✦ (2.2% after taking into account the
              pity system).
            </li>
            <li>
              <strong>7.0%</strong> for 5✦ (13.7% after taking into account the
              pity system).
            </li>
            <li>
              <strong>91.8%</strong> for 4✦ (84.1% after taking into account the
              pity system).
            </li>
          </ul>
          <li>Probabilities for Memory Traces:</li>
          <ul>
            <li>
              <strong>2%</strong> for SSR (3.37% after taking into account the
              pity system).
            </li>
            <li>
              <strong>7%</strong> for SR (13.7% after taking into account the
              pity system).
            </li>
            <li>
              <strong>91.0%</strong> for R (82.93% after taking into account the
              pity system).
            </li>
          </ul>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesReroll;

export const Head: React.FC = () => (
  <Seo
    title="Reroll Guide | Ash Echoes | Prydwen Institute"
    description="Start your adventure in Ash Echoes with the best character using our Reroll Guide."
    game="ash"
  />
);
